// import '../styles/home.css'
import React from "react"
import { Link } from "gatsby"
import { Container, Row, Col, Button } from "react-bootstrap"
import ShareComp from "./ShareComp"
import { AiOutlineTwitter } from "@react-icons/all-files/ai/AiOutlineTwitter"
import { AiFillInstagram } from "@react-icons/all-files/ai/AiFillInstagram"
import { FaFacebookF } from "@react-icons/all-files/fa/FaFacebookF"
import { FaLinkedinIn } from "@react-icons/all-files/fa/FaLinkedinIn"
import { FaPinterestP } from "@react-icons/all-files/fa/FaPinterestP"

// import logo from "../images/logo.png"
import useSitedata from "./useSitedata"
import { StaticImage } from "gatsby-plugin-image"
import { AiOutlineMail } from "@react-icons/all-files/ai/AiOutlineMail"

export default function Footer() {
  const email = useSitedata("email")
  const url = useSitedata("url")
  const artworksPageName = useSitedata("artworksPageName")
  const projectsPageName = useSitedata("projectsPageName")

  const fSize = "1.2em"

  return (
    <Container fluid className="bg-dark text-white-50 mt-5 pt-5">
      <Row>
        <Col md={12} lg={3} className="ps-5 pt-5">
          <h4 className="text-white">
            <Link to="/" className="h3 nav-link">
              <StaticImage
                src="../images/logo.png"
                alt="coordinarts logo"
                width="200"
                placeholder="blurred"
              />
            </Link>
          </h4>
          <p className="pt-2">
            Customize new designs and artworks. <br />
            Order and explore existing artworks. <br />
            Simple, easy and satisfying.
          </p>
          <ShareComp url={url} headColor="rgb(255,255,255)" />
          <p>
            {" "}
            <span style={{ color: "rgb(255,255,255)", fontSize: "1.0em" }}>
              Connect
            </span>{" "}
            <br />
            <a
              href={"https://www.facebook.com/Coordinarts-109880461740016"}
              target="_blank"
              rel="noopener noreferrer"
            >
              <span style={{ fontSize: fSize }}>
                <FaFacebookF />
              </span>
            </a>
            &nbsp;
            <a
              href={"https://www.instagram.com/coordinarts"}
              target="_blank"
              rel="noopener noreferrer"
            >
              <span style={{ fontSize: fSize }}>
                <AiFillInstagram />
              </span>
            </a>
            &nbsp;
            <a
              href={"https://twitter.com/coordinarts"}
              target="_blank"
              rel="noopener noreferrer"
            >
              <span style={{ fontSize: fSize }}>
                <AiOutlineTwitter />
              </span>
            </a>
            &nbsp;
            <a
              href={"https://www.linkedin.com/in/coordinarts-344942242/"}
              target="_blank"
              rel="noopener noreferrer"
            >
              <span style={{ fontSize: fSize }}>
                <FaLinkedinIn />
              </span>
            </a>
            &nbsp;
          </p>
        </Col>

        <Col xs={12} sm={4} lg={3} className="ps-5 pt-5">
          <h4 className="text-white">&nbsp;&nbsp;Contact</h4>
          <p className="pt-2">&nbsp;&nbsp;&nbsp;{email}</p>
          <p>
            <a
              className="nav-link text-info"
              href="mailto:yoda@coordinarts.com"
            >
              <AiOutlineMail /> Email us
            </a>
          </p>
        </Col>

        <Col xs={12} sm={4} lg={3} className="ps-5 pt-5">
          <h4 className="text-white">&nbsp;&nbsp;Quick links</h4>
          <Link to="/" className="nav-link text-info">
            Home
          </Link>
          <Link to={"/" + artworksPageName} className="nav-link text-info">
            Heartworks
          </Link>
          <Link to={"/" + projectsPageName} className="nav-link text-info">
            Projects
          </Link>
          <Link to="/about" className="nav-link text-info">
            About
          </Link>
        </Col>

        <Col xs={12} sm={4} lg={3} className="ps-5 pe-5 pt-5">
          <h4 className="text-white">Sign up</h4>
          <p className="pt-2">
            Want to get updates about our new artworks, projects and discounts
            right in your inbox?
          </p>
          <a
            class="signup"
            href="https://forms.gle/a4UPEPKeV4sgdyQe9"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button variant="outline-info">
              {" "}
              <AiOutlineMail /> Provide your email
            </Button>
          </a>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col className="p-1 pb-5 text-center">
          Copyright © 2022 Coordinarts. All rights reserved.
        </Col>
      </Row>
    </Container>
  )
}
