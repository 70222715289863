import { useStaticQuery,graphql } from "gatsby"
// this data shall be used to return page names etc. 

const useSitedata=(argData)=>{

    // first get the data using grahiql query
    const queryDataSite=useStaticQuery(graphql`
    query siteDataQuery {
          site {
            siteMetadata {
              title
              url
              keywords
              description
              email
              projectsPageName
              artworksPageName
            }
        }
      }
      `
    )

    // console.log(queryDataSite)
    let mydata=''

    if (argData==='projectsPageName'){
        mydata=queryDataSite.site.siteMetadata.projectsPageName;
    }
    if (argData==='artworksPageName'){
        mydata=queryDataSite.site.siteMetadata.artworksPageName;
    }
    if (argData==='email'){
        mydata=queryDataSite.site.siteMetadata.email;
    }
    if (argData==='title'){
        mydata=queryDataSite.site.siteMetadata.title;
    }
    if (argData==='url'){
        mydata=queryDataSite.site.siteMetadata.url;
    }
    if (argData==='keywords'){
        mydata=queryDataSite.site.siteMetadata.keywords;
    }
    if (argData==='description'){
        mydata=queryDataSite.site.siteMetadata.description;
    }
    return mydata;
}

export default useSitedata;