import React from "react"
import { useState } from "react"
import { Button, Alert } from "react-bootstrap"
import { AiOutlineTwitter } from "@react-icons/all-files/ai/AiOutlineTwitter"
import { FaFacebookF } from "@react-icons/all-files/fa/FaFacebookF"
import { FaLinkedinIn } from "@react-icons/all-files/fa/FaLinkedinIn"
import { FaPinterestP } from "@react-icons/all-files/fa/FaPinterestP"
import { MdEmail } from "@react-icons/all-files/md/MdEmail"
import { IoLogoWhatsapp } from "@react-icons/all-files/io/IoLogoWhatsapp"
import { AiFillCopy } from "@react-icons/all-files/ai/AiFillCopy"

export default function ShareComp(shareData) {
  const url = shareData.url
  const headColor = shareData.headColor
  const fSize = "1.2em"

  const [copyButtonColor, setCopyButtonColor] = useState("rgb(13,110,253)")

  function copyFn() {
    navigator.clipboard.writeText(url)
    setCopyButtonColor("rgb(71,129,126)")
    // alert("clicked.")
  }

  const shareElement = (
    <p>
      {" "}
      <span style={{ color: headColor, fontSize: "1.0em" }}>Share</span> <br />
      <a
        href={"https://www.facebook.com/sharer/sharer.php?u=" + url}
        target="_blank"
        rel="noopener noreferrer"
      >
        <span style={{ fontSize: fSize }}>
          <FaFacebookF />
        </span>
      </a>
      &nbsp;
      <a
        href={"https://pinterest.com/pin/create/button/?url=" + url}
        target="_blank"
        rel="noopener noreferrer"
      >
        <span style={{ fontSize: fSize }}>
          <FaPinterestP />
        </span>
      </a>
      &nbsp;
      <a
        // href={"https://twitter.com/share?url="+url+'&text='+title+'&via'+'twitterHandle'}
        // href={"https://twitter.com/share?url="+url}
        href={"https://twitter.com/intent/tweet?url=" + url}
        target="_blank"
        rel="noopener noreferrer"
      >
        <span style={{ fontSize: fSize }}>
          <AiOutlineTwitter />
        </span>
      </a>
      &nbsp;
      <a
        href={"https://www.linkedin.com/shareArticle?url=" + url}
        target="_blank"
        rel="noopener noreferrer"
      >
        <span style={{ fontSize: fSize }}>
          <FaLinkedinIn />
        </span>
      </a>
      &nbsp;
      <a
        href={
          "mailto:?subject=Check out this link &body=Found this interesting. You may go through it as well. " +
          url
        }
        title="Share by Email"
      >
        <span style={{ fontSize: fSize }}>
          <MdEmail />
        </span>
      </a>
      &nbsp;
      <a href={"whatsapp://send?text=" + url} title="Share by whatsapp">
        <span style={{ fontSize: fSize }}>
          <IoLogoWhatsapp />
        </span>
      </a>
      &nbsp;
      <Button onClick={copyFn} variant="link" className="ps-0 ms-0">
        <span style={{ color: copyButtonColor, fontSize: fSize }}>
          <AiFillCopy />
        </span>
      </Button>
    </p>
  )

  return shareElement
}
