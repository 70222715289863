import React from 'react'
import NavbarHeader from './NavbarHeader'
import Footer from './Footer'
import '../styles/global.css'

export default function Layout({children}) {
  return (
    <div className='layout'>
        <NavbarHeader />
        <div className="content">
            { children }
        </div>
        <Footer />
    </div>
  )
}
