import React from "react"
import { Link } from "gatsby"
import { Container, Navbar, Nav } from "react-bootstrap"
// import logo from "../images/logo.png"
import useSitedata from "./useSitedata"
import { StaticImage } from "gatsby-plugin-image"

export default function NavbarHeader() {
  const artworksPageName = useSitedata("artworksPageName")
  const projectsPageName = useSitedata("projectsPageName")

  return (
    <header>
      <Container>
        <Navbar expand="md" collapseOnSelect={true}>
          <Link to="/" className="h3 nav-link">
            {/* <img src={logo} alt="coordinarts logo" width="200" /> */}
            <StaticImage
              src="../images/logo.png"
              alt="coordinarts logo"
              width="200"
              placeholder="blurred"
            />
          </Link>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="text-center ms-auto">
              <ul className="navbar-nav">
                <li>
                  <Link to="/" className="nav-link" activeClassName="active">
                    Home
                  </Link>
                </li>
                <li>
                  <Link
                    to={"/" + artworksPageName}
                    className="nav-link"
                    activeClassName="active"
                  >
                    Heartworks
                  </Link>
                </li>
                <li>
                  <Link
                    to={"/" + projectsPageName}
                    className="nav-link"
                    activeClassName="active"
                  >
                    Projects
                  </Link>
                </li>
                <li>
                  <Link
                    to="/contact"
                    className="nav-link"
                    activeClassName="active"
                  >
                    Contact
                  </Link>
                </li>
                <li>
                  <Link
                    to="/about"
                    className="nav-link"
                    activeClassName="active"
                  >
                    About
                  </Link>
                </li>
                {/* <li>
                  <Link
                    to="/test"
                    className="nav-link"
                    activeClassName="active"
                  >
                    Test
                  </Link>
                </li> */}
              </ul>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </Container>
    </header>
  )
}
