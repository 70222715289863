import React from "react"
import { Helmet } from "react-helmet"
import useSitedata from "./useSitedata"
import favicon from "../images/favicon.png"
import logo from "../images/logo.png"

const Headmeta = ({ importedIn, importedUrl }) => {
  // if (importedIn==='index') {
  //      title = useSitedata("title")
  // }else if (importedIn==='projects') {
  //     title = useSitedata("projectsPageName")
  // }else if (importedIn==='artworks') {
  //     title = useSitedata("artworksPageName")
  // } else {
  //     title = useSitedata("title")
  // }
  // react hooks cannot be used in if else or loops

  // if title value is true then make the first letter upper
  let title = importedIn
    ? importedIn[0].toUpperCase() + importedIn.slice(1)
    : importedIn

  // let title=importedIn[0].toUpperCase() + importedIn.slice(1);
  const commonTitle = useSitedata("title")
  const commonDescription = useSitedata("description")
  const commonKeywords = useSitedata("keywords")
  const commonUrl = useSitedata("url")
  // const defaultTitle="Coordinarts"
  // console.log('title is: ',commonTitle,commonDescription,commonKeywords,commonUrl)

  let pageUrl = importedUrl ? `${commonUrl}/${importedUrl}` : commonUrl
  // console.log(pageUrl)
  let pageDescription = commonDescription
  let pageKeywords = commonKeywords
  // let pageUrl = commonUrl
  let pageTitle = `${title} | ${commonTitle}`

  return (
    <>
      {/* for SEO, title should make some sense so it's better to write a bit more than just a word in the 
     title. next is speed which should be high. third is strucutre, ie the links should also make sense.*/}
      <Helmet
        defer={false}
        htmlAttributes={{ lang: "en" }}
        title={title}
        titleTemplate={`%s | ${commonTitle}`}
        defaultTitle={commonTitle}
        link={[
          {
            rel: "shortcut icon",
            type: "image/png",
            href: `${favicon}`,
          },
        ]}
        meta={[
          {
            name: "description",
            content: pageDescription,
          },
          {
            name: "keywords",
            content: pageKeywords,
          },
          {
            property: `og:title`,
            content: pageTitle,
          },
          {
            property: "og:description",
            content: pageDescription,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            property: "og:image",
            content: logo,
          },
          {
            property: "og:url",
            content: pageUrl,
          },
          {
            name: "twitter:title",
            content: pageTitle,
          },
          {
            name: `twitter:creator`,
            content: "@Coordinarts",
          },
          {
            name: `twitter:site`,
            content: "@Coordinarts",
          },
          {
            name: "twitter:card",
            content: "summary",
          },
        ]}
      />
    </>
  )
}

export default Headmeta
